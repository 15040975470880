.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.onlineAvailability {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.onlineAvailabilityLabel {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.maxPartySizeInput {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }
}

.description {
  margin: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-bottom: 6px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: bold;
}