@import '../../styles/propertySets.css';

.root {
  color: var(--matterColorDark);

  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceSmallFontStyles;
    color: var(--matterColor);
    
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.list {
  list-style: square;
  margin-left: 45px;
}

.space {
  margin: 0 0 0 45px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(calc(33% - 50px), 1fr));

}

.gridItem {
  border: 1px solid var(--matterColor);
  padding: 6px;
}