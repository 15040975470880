@import '../../styles/propertySets.css';

.root {
  text-align: center;
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin: 80px auto 16px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  font-weight: 500;
  font-size: 30px;
}

.mainContent {
  width: 80%;
  margin: 0 auto;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }

  & label {
    text-align: left;
    font-size: 16px;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
