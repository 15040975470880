@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  font-family: Roboto;

}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background-color: white;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.actionWrapper{
  position: relative;
  margin-bottom: 48px;
}

/* ------------- SECTION IMAGES ------------- */

.sectionImages {
  @media (--viewportMedium) {
    /* padding: 0 32px; */
  }
}

.imagePortrait{
  padding: 24px;

  @media (--viewportMedium) {
    padding: 0;
    width: fit-content;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  display: flex;
  /* justify-content: space-between;; */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapperProduct {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 100%; /* 3:2 Aspect Ratio */
  display: flex;
  /* justify-content: space-between;; */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.aspectWrapper--empty {
  background-color: var(--matterColorNegative);
  @media (--viewportMedium) {
    padding-bottom: 380px;
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 1px;
  width: calc(100% - 2px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: 2px;
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.imagesWrapper--2, .imagesWrapper--3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.imagesWrapper--3 > .rootForImage{
  @media (--viewportLarge) {
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading BG color */


  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: 410px; /* height: 380px; */
    object-fit: cover;
  }
}

.rootForImagePortrait {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading BG color */
  margin-bottom: 12px;

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: 410px; /* height: 380px; */
    object-fit: cover;
  }
}

.rootForImage--0 {
  justify-content: space-between;
}

.rootForImage--1 {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.rootForImage--2 {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: 10px;

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.5);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

/* ------------ CONTENT WRAPPERS ------------ */

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
   
  }

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1600px; /* max-width: 1128*/
    padding: 0 36px;
    margin: 0 auto 117px;
    /* border: 2px blue solid; */
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
    
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 760px); /*max-width: calc(100%-433px)*/
    flex-basis: calc(100% - 400px);
    flex-shrink: 0;
    flex-grow: 1;
    /* border: 2px solid red; */
  }
}

.bookingFlex{
  @media (--viewportMedium){
    display: flex;
  flex-direction: column;
    align-items: normal;
    width: 30%;
  }

}
.bookingPanel {

  @media (--viewportLarge) {
    display: block;
    margin-top: 50px;
    margin-left: 60px;
    /* border-left-style: solid; */
    /* border-left-width: 0; */
    padding: 15px;
    /* border-left-color: var(--matterColorNegative); */
    

    /*padding-left: 60px;*/

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
    max-width:402px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 50px;
    /*margin-left: 60px;*/
    /* border-left-style: solid; */
    /* border-left-width: 0; */
    padding: 15px;
    /* border-left-color: var(--matterColorNegative); */
    

    /*padding-left: 60px;*/

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionSeparator {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-style: solid;
}

/* ------------ SECTION AVATAR ------------ */

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;


  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  /*width: 60px;*/
  width: calc(100% - 72px);

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
    margin-top: 0;
    width: auto;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;
  border: solid 3px #fff;
  box-shadow: 0 0 0 1px #d5d5d5;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;
  border: solid 4px #fff;
  box-shadow: 0 0 0 2px #d5d5d5;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

/* ------------ SECTION HEADING ------------ */
.avatar{
  display: flex;
  align-items: center;
}

.avatarLink:hover{
  text-decoration: none;
  background: var(--listingGray);
}

.instructorReviews{
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.guide{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sectionHeading {
  margin-bottom: 34px;  

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
    
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
    
    width: 100%;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: black;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;


  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-left: 5px; */
  /* text-transform: uppercase; */
  line-height: 1.5;
  

  @media (--viewportMedium) {
    font-size: 14px;
    /* margin-top: 0;
    margin-bottom: 2px; */
  }
}

.generalInformation {
  display: flex;
  margin-top: 5px;
}

.metadata {
  display: inline-block;
  /* margin: 0 20px 5px 0; */
  margin: 0px;
  font-size: 12px;
  line-height: 1;
  color: #c5c5c5;
  font-weight: bold;
}

.author {
  width: 100px;
  height: 100%
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  

  @media (--viewportMedium) {
    /* margin-top: 10px; */
    margin-bottom: 0;
    margin-left: 10px;
    color: var(--matterColor);
    
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  /* margin: 0; */

  font-size: 12px;
  letter-spacing: 0.05px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px solid #3b82f6;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: var(--fontWeightBold);
  color: #3b82f6;
}

/* ------------ SECTION DESCRIPTION ------------ */

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 20px;
  

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 24px;
    /* border: 1px solid black; */
    max-width: 650px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: black;


  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.2;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

/* ------------ SECTION FEATURES ------------ */

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 24px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.activityList {
  margin-bottom: 20px;
  background-color: #f8f8f8;
  width: fit-content;
  padding: 10px 20px;


  @media (--viewportMedium) {
    margin-bottom: 24px;
    width: fit-content;
    padding: 16px 24px;
    
  }
}

/* ------------ SECTION MAP ------------ */

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;
  

  @media (--viewportMedium) {
    margin-top: 24px;
    padding: 0;
    margin-bottom: 0;
    
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.mapInMobile {
  @media (--viewportLarge) {
    display: none;
  }
  @media (--viewportLargeWithPaddings) {
    display: none;
  }
  @media (--viewportXLarge) {
    display: none;
  }
}

.mapInDesktop {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

/* ------------ SECTION REVIEWS ------------ */

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin: 0 0 20px;

  @media (--viewportMedium) {
    font-size: 20px;
    margin: 0 0 27px;
  }
  @media (--viewportLarge) {
    margin: 0 0 26px;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.formContainer {
  width: 100%;
  height: 75px;
}

.addCommentAvatar {
  width: 35px;
  height: 35px;
  margin-right: 11px;
  float: left;
}

.commentForm {
  flex: 1 1 auto;
  text-align: right;
  float: left;
  width: calc(100% - 50px);
}

.commentFormTextarea {
  border: 1px solid #e5e1e6;
  padding: 8px 14px;
  box-sizing: border-box;
  margin-bottom: 6px;
  font-size: 16px;
  width: calc(100% - 70px);
  float: left;
  height: 36px !important;

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
     border-color: var(--matterColor);
     outline: none;
  }
}

.commentFormSubmit {
  @apply --marketplaceButtonFontStyles;
  font-size: 14px;
  line-height: 1;

  /* Dimensions */
  padding: 10px 13px;

  /* Borders */
  border: 1px solid #e5e1e6;

  /* Colors */
  color: #979797;

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px;

  &:hover,
  &:focus {
     outline: none;
     background-color: #e5e1e6;
     text-decoration: none;
  }
}

.comment {
  display: flex;
  margin-bottom: 26px;
}

.comment__avatar {
  box-shadow: var(--boxShadow);
  width: 35px;
  height: 35px;
  border: 1px solid white;
  margin-right: 11px;

& > span {
    font-size: 12px;
    padding: 0;
  }
}

.comment__content {
  flex: 1 1 auto;
}

.comment__message {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px;
}

.comment__author {
  margin-right: 11px;
  color: #b2b2b2;
}

.readMore {
  color: #6bb0bf;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    /* For aligning rating starts in the middle
    * of a paragraph line */
    display: contents;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}