@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.imageSection {
  @media (--viewportSmall) {
    flex-basis: 30%;
    width: 30%;
  }
}

.listingWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 26.6667%; /* 3:2 Aspect Ratio */
  background: transparent; /* Loading BG color */
  width: 100%;
}


.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 25px;
  @media (--viewportSmall) {
    height: 100%;
    object-fit: cover;
  }
}

.wrapperForButton {
  position: relative;
  width: 100%;
  @media (--viewportSmall) {
    transition: var(--transitionStyleButton);
    &:hover {
      transform: scale(1.02);
    }
  }
}

.likeButton {
  position: absolute !important;
  color: var(--marketplaceColor) !important;
  top: 0;
  left: 0;
}

.mobileInfoSection {
  margin-top: 45%;

  @media (--viewportSmall) {
    display: none;
  }
}

.infoSection {
  display: none;

  width: 70%;
  flex-basis: 70%;
  padding-left: 10px;
  padding-top: 15px;
  color: var(--listingGray);

  @media (--viewportSmall) {
    display: block;

  }
}



.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 5px;
  display: inline-block;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.activities {
  color: var(--listingGray);
  font-size: 14px;
  line-height: 1.1;
  margin: 0 0 12px;
}

.listingMetadata {
  font-size: 12px;
  color: var(--listingGray);
  line-height: 1.1;
}

.listingMetadata span {
  margin-right: 15px;
}

.listingMetadata svg {
  padding-right: 5px;
}


.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.reviewAndPrice {
  width: 100%;
}

.reviewsAvg {
  width: 60%;
  float: left;
}

.listingPrice {
  width: 50%;
  float: right;
  text-align: right;
  font-size: 18px;
  color: var(--matterColor);
}



.mobilePrice {
  width: 25%;
  float: left;
  color: #6bb0bf;
  font-weight: bold;
  font-size: 18px;
}

.labelPrice {
  color: #707070;
  font-size: 14px;
}

.mobileInfo {
  width: 70%;
  padding-left: 10px;
  float: left;
  font-weight: bold;
  font-size: 18px;
  color: #707070;
}
