@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.fieldsWrapper {
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    border-radius: 12px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    box-shadow: var(--boxShadowLight);
    height: 64px;
  }
  
.mobileInputRoot {
  width: 100%;
  /* padding-left: 38px; */

  /* Font */
  /* @apply --marketplaceH1FontStyles; */

  /* font-size: 20px; */
}

.desktopInputRoot {
  /* height: var(--topbarHeightDesktop); */
  width: 100%;
  display: flex;
}

.desktopSelectElement{
    width: 100%;
    background-color: var(--matterColorLight);
}

.desktopInputRoot > svg {
    margin: 15px 0px 0px 10px;
}

.desktopInputElement{
  border-radius: 0px;
  border: none;
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Layout */
  display: flex;
  align-items: center;
  padding: 4px 24px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;
  font-size: 18px;


  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }
}

.desktopIcon {
 display: flex;
}

.desktopIconText{
  margin: auto;
  font-weight: 700;
}

.iconRoot {
  margin: auto 0;
  stroke: var(--listingGray);
}

.icon {
  margin: auto;
  stroke: var(--matterColorBright);
}

.desktopInput {
    flex-grow: 1;
    /* Font */
    @apply --marketplaceH4FontStyles;
    height: var(--topbarHeightDesktop);
    line-height: unset;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
  
    /* Search text gets ellipsis if it's too wide */
    text-overflow: ellipsis;
    overflow-x: hidden;
  
    &:hover,
    &:focus {
      border-bottom-color: var(--marketplaceColor);
      outline: none;
    }
  
    &:hover::placeholder,
    &:focus::placeholder {
      color: var(--matterColor);
    }
  
    /* Placeholder text might be too wide */
    &::placeholder {
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-weight: var(--fontWeightMedium);
      transition: var(--transitionStyleButton);
    }
  
    margin-top: 0;
    margin-bottom: 0;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: var(--fontWeightSemiBold);
    }
  }
  

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}

.desktopSearchInputRoot, .desktopSelectRoot{
    /* padding-right: 20px; */
    display: flex;
    flex-grow: 1;
    border-right: 2px solid #e2e2e2;
    font-size: 16px;
    line-height: 24px;
    padding: 5px;
    border: none;
    & select {
      font-size: 16px;
      min-width: 140px;
      height: 100%;
      padding: 2px 15px;
  
      background-size: 13px 13px;
    }

    &::placeholder {
      font-size: 16px!important;
    }

    @media(--viewportMedium){
      padding: 2px 15px;
    }
  }

  .desktopSelectRoot > svg {
      margin: 15px 0px 15px 10px;
  }

  .desktopSelectRoot select {
      border-radius: 0px;
      border: none;
  }
  
    .topbarSearchInput {
      font-size: 16px;
      color: var(--marketplaceColorDark);
      min-width: 250px;
      padding: 2px 15px;
      padding-left: 20px;
    
      border-left: 1px solid var(--matterColor);
      border-bottom: none;
    }
  
    /* Search */
  .searchLink {
    flex-grow: 1;
    height: 100%;
    margin: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submitButton {
    display: flex;
    background-color: var(--marketplaceColor);
    border-radius: 25px;
    
    padding: 0 8px;
    margin: auto 0;
    width: 42px;
    height: 42px;
    min-height: 42px;
    min-width: 42px;

    &:hover {
      background-color: var(--marketplaceColorDark);
    }
  }
  
  .buttonText{
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    vertical-align: bottom;
  }

  .divider{
    margin: 0.5rem 0;
    overflow: hidden;
    border-right: 2px solid var(--matterColorLightGrey);
  }

  .searchInput{
    background-color: var(--matterColorLight);
    border: none;
    font-size: 10px;
    padding: 10px 5px;

    @media(--viewportMedium){
      font-size: 16px;
      padding: 10px 18px;
    }
  }

  .desktopSearchInputRoot::-webkit-input-placeholder,
  .searchInput::-webkit-input-placeholder{
    font-size: 10px;

    @media(--viewportMedium){
      font-size: 16px;
    }
  }
  .typeWrapper {
    display: flex;
    flex-direction: row;
  }