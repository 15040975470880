@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
}

.select {
  color: var(--matterColorAnti);
  border: none;
  background-color: var(--matterColorLightGray);
  border-radius: 6px;
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.description {
  margin: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-bottom: 6px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: bold;
}