@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 12px 24px 0 24px;
  /* background-color: white; */
  border-radius: 15px;
  padding: 10px 20px 20px;
  background: rgba(255, 255, 255, 0.5);


  @media (--viewportMedium) {
    width: 80%;
    padding: 36px;
    margin: 0 7.5vw 0 7.5vw;
    border-radius: 25px;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 36px 36px 36px 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 0 0 7%;
    max-width: calc(1052px + 36px + 36px);
    padding: 36px 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceH1FontStyles;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColorDark);

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorDark);
  margin: 30px 0 45px;

  font-size: 22px;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSearchbar {
  display: flex;
  flex-direction: column;
  position: relative;
  
  composes: animation;
  animation-delay: 0.7s;

  @media (--viewportMedium){
    width: 100%;
  }
}

.heroIconRoot {
  position: absolute;
  left: 10px;
}

.heroInputRoot {
  padding-left: 34px;
  font-size: 16px;
  background-color: white;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}
.fieldsWrapper {
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border-radius: 6px;
  padding: 5px 10px;
  margin-top: 10px;
  height: 50px;

  @media (--viewportMedium){
    width: 100%; 
  }

}