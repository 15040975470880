@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  font-family: Roboto;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

/** THUMBNAIL CLASSES **/

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  
  position: relative;
  transition: var(--transitionStyleButton);
  border-radius: 6px;
  margin: auto;

  @media (--viewportMedium){
    margin: 0;
    width: 100%;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 107.5%; 
  border-radius: 6px;
  display: flex;

  @media (--viewportMedium) {
    display: flex;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;



  @media (--viewportMedium) {
    width: 100%;
    height: 100%;
  }
}

/** CONTENT **/

.info {
  padding: 5px 0;
}

.infoCard {
  padding: 10px;
  position: absolute; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-family: Roboto;
  color: white;
  padding: 20px; 

 @media (--viewportLarge) {
  padding: 20px; 
 }


  
  /* margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px; */
  
}

.overlay{
  padding: 5px; 
  position: absolute; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .35;
  border-radius: 6px;
}

.rowOne{
  display: flex; 
  justify-content: space-between;
}

.rowThree{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rowFour{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px; */
}

.flex{
  display: flex;
}

.grid{
  display: grid; 
  margin-left: 10px;
}

.adventureTitle{
  flex-grow: 0;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.08px;
  text-align: left;
  max-height: 72px;
  overflow: hidden;
  margin-top: 12px;
  width: 190px;
}

.adventureDescription {
  flex-grow: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
  text-align: left;
  max-height: 56px;
  overflow: hidden;
  line-height: 18px;
  width: 190px;

  @media (min-width: 1280px) {
    max-height: 72px;
  }
}

.likeIcon{
  color:white;
  height: 20px;
}
.avatar{
  width: 32px;
  height: 32px;
  flex-grow: 0;
  background-color: #c4c4c4;
  border-radius: 50%;
  position: relative !important;
}

.icon{
  width: 68px;
  height: 32px;
  flex-grow: 0;
  padding: 4px 8px;
  border-radius: 6px;
  /* -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px); */
  background-color: rgba(217, 201, 201, 0.35);
  text-align: center;
  border-color: white;
}

.rowTwo{
  flex-grow: 1;
}

.icons{
  height: 15px;
  width: 15px;
}

.iconsHolder:last-of-type {
  margin-left: 13px;
}

.listingMetadata {
  font-size: 9px;
  color: var(--listingGray);
  line-height: 1.1;
}

.listingMetadata span {
  margin-right: 15px;
}

.listingMetadata svg {
  padding-right: 5px;
}

.userName {
  /* Font */
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: white;
  width: 100px;
  height: 21px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;

  /* Remove default margins from font */
  /* margin-top: 0;
  margin-bottom: 0; */
  /* text-transform: uppercase; */

  @media (--viewportMedium) {
  	font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.activities {
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
}

/** PRICE **/

.price {
  /* Layout */
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.6;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: white;
  font-weight: 400;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 14px;
  line-height: 1.6;
}

.sitesAvailable{
  font-size: 14px;
  font-family: Roboto;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  height: fit-content;
  /* Remove default margins from font */
  align-self: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  font-family: Roboto;
  line-height: 1.6;
}

.button{
  background-color: var(--marketplaceColor);
  flex-grow: 0;  
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: center;
  border-radius: 6px;
  border: none;
  color: white;
  padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
  cursor: pointer;
}

.button:hover{
  background-color: var(--marketplaceColorLight);
}

.iconsDiv{
  display: flex;
  flex-direction: row;
  min-width: fit-content;
}

.likeButton {
  position: absolute !important;
  background-color: var(--matterColorLightTransparent);
  color: var(--matterColorBright) !important;
  width: 50px;
  height: 30px;
  border-radius: 6px;
  top: 15px;
  right: 15px;
  z-index: 100;
}

.likedButton {
  position: absolute !important;
  background-color: var(--matterColorLightTransparent);
  color: var(--failColor) !important;
  width: 50px;
  height: 30px;
  border-radius: 6px;
  top: 15px;
  right: 15px;
  z-index: 100;
}

.likeButton:hover,
.likedButton:hover {
  background-color: var(--matterColorLightTransparent);
}