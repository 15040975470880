@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.hero {
  background: url('./img/Hero.png');
  background-size: 100%;
  height: 400px;
  margin: 0;
  padding: 0;
  display:flex;
}

.heading {
  @apply --marketplaceH1FontStyles;
  font-size: 45px;

  margin: auto;
  color: var(--matterColorBright);

  @media (--viewportMedium) {
    font-size: 55px;
    padding-left: 95px;
    margin: auto 0;
  }
}

.content {
  margin: 45px 24px;
  @media (--viewportLarge) {
    margin: 45px 95px;
    max-width: 650px;
  }
}
