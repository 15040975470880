.root {
}

.marketplaceColorStroke {
  stroke: var(--marketplaceColor);
}

.marketplaceColorFill {
	fill: var(--marketplaceColor);
}
