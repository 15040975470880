.root {
}

.input{
  border: none;
  background-color: var(--matterColorLightGray);
  border-radius: 6px;
}

.input:disabled {
	border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
    &::placeholder {
    	color: var(--matterColorNegative);
    }
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}

.textarea {
  border: none;
  background-color: var(--matterColorLightGray);
  border-radius: 6px;
}

.description {
  margin: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-bottom: 6px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
