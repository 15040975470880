@import '../../styles/propertySets.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --backgroundImage;
    margin: 0 2.5%;
    border-radius: 5px 5px 0 0;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding-top: 19px;
  
  @media (--viewportMedium) {
    border: solid 2px var(--matterColorAnti);
    padding-left: 120px;
    padding-right: 120px;
    min-width: 640px;
  }
}

.form {
  @apply --marketplaceModalFormRootStyles;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-weight: normal;
  font-size: 22px;
  @media (--viewportMedium) {
    font-weight: normal;
    font-size: 30px;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin-top: 0;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 24px;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}
