@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.partySizeField {
  margin: 24px 0 0;

  & label {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 8px;
  }

  & select {
    padding-top: 7px !important;
    padding-bottom: 8px !important;
    border-color: var(--matterColor);
    background-color: #fff;
    height: 48px;
  }

  @media (--viewportMedium) {
    margin: 0;
    margin-top: 15px;
  }
}

.select {
  padding: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.onlineCheckbox {
  display: block;
  padding: 0 24px;
  margin-top: 24px;
  @media (--viewportMedium) {
    padding: 0;
    margin-top: 15px;
  }
}

.formWrapper {
  margin-bottom: 28px;
}

.bookingDates {
  flex-shrink: 0;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--listingDark);

  margin-top: 0;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint,
.phoneHelper {
  @apply --marketplaceTinyFontStyles;
  font-weight: 500;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 12px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 12px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
  }
}

.phoneHelper {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.select option:disabled {
  color: var(--listingGray);
  cursor: not-allowed;
}