@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
  padding: 24px;

  justify-content: flex-start;

  @media (--viewportMedium) {
    padding: 36px;

    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 35px;

  @media (--viewportMedium) {
    margin-top: 65px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  line-height: 1.1;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
  font-weight: normal;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
  font-size: 13px;
  font-weight: bold;
  color: var(--matterColor);
}

.submitButton {
  width: 100%;
}
