@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
  padding: 24px;
  justify-content: flex-start;

  @media (--viewportMedium) {
    padding: 36px;
    justify-content: space-between;
  }
}

.name {
  display: flex;
  margin-top: 8px;

  @media (--viewportMedium) {
    margin-top: 10px;
  }
}

.firstNameRoot {
  width: 44%;

  & > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 1px;
    border-right-color: #c5c5c5;

    &:hover, &:focus {
      border-right-color: #c5c5c5;
    }
  }
}

.lastNameRoot {
  width: 56%;

  & > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 1px;
    border-left-color: #c5c5c5;

    &:hover, &:focus {
      border-left-color: #c5c5c5;
    }
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 25px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.submitButton {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #93c5fd;
}
