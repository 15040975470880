@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  flex-grow: 1;
  width: calc(100% - 48px);
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 36px;
  background-color: var(--matterColorBright);
  margin: 24px;

  @media (--viewportLarge){
    margin: 24px auto;
    width: 50%;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  margin-top: 0;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
    font-size: 30px;
  }
}
