@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 30px; /* 300px*/
  height: 67.5vh;  /* 67.5vh*/
  max-height: 420px; /* 600px*/
  padding: 0;

  @media (--viewportMedium) {
    min-height: 200px; /*500px*/
    height: 50vh; /* 70vh*/
    max-height: none; /*none*/
  }

  @media (--viewportLarge) {
    /* max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop)); */
    max-height: 600px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.howitworks{
  width: 100%;
 
}
.hero {
  flex-grow: 1;
  
  @media (--viewportMedium) {
    padding: 0;
    justify-content: center;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: hidden;
  width: 100%;
  /* margin-top: 40px; */
}

.sectionHow {
  /* overflow: hidden; */
  width: 100%;
  background-color: #3b82f6;
  /* margin-top: 40px; */
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  background-color: #3b82f6;
  display: flex;
  justify-content: center;
  color: var(--matterColorBright);
  /* border: 3px solid red; */
  max-width: 100%;

  @media (--viewportLarge) {
    padding: 36px 36px 36px 36px;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
  background-color: var(--matterColorBright);
  border: none;
}