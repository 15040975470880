@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  padding: 24px;

  @media (--viewportLarge){
    padding: 96px 64px;
  }

  @media (--viewportLargeWithPadding){
    padding: 96px 64px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);
  margin: 0;

  @media (--viewportLarge){
    margin: 0 25px;
  }
}

.subtitle {
  font-size: 14px;
  margin: 0 0 24px;
  color: var(--matterColorDark);
  @media (--viewportLarge){
    margin: 0 25px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportLarge){
    text-align: left;
    width: 30%;
    margin-left: 64px;
  }
}

.image {
  width: 100%;
  @media (--viewportLarge){
    width: 80%;
  }
}

.buttonSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto 0 12px;
  justify-content: center;

  @media (--viewportLarge){
    width: 70%;
  }
}

.button {
  width: 100%;
  margin: 0 auto 0;
  text-align: center;
  padding: 15px 15px;

  background-color: var(--marketplaceColor);
  color: var(--matterColorBright);
  height: 48px;

  border: none;
  border-radius: 6px;

  @media (--viewportLarge){
    margin: auto 24px;
    width: 75%;
  }

  @media (--viewportLargeWithPadding){
    margin: auto 24px;
    width: 40%;
  }
}

.logo {
  width: 125px;
  margin: 12px auto;
  
  @media (--viewportLarge){
    width: 100px;
    margin: 12px 25px;
  }
}

.link{
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 12px auto;
  width: 100%;

  @media (--viewportLarge){
    flex-direction: row;
    margin: 0 auto;
    width: 65%;
  }

  @media (--viewportLargeWithPadding){
    flex-direction: row;
    margin: 0 auto;
    width: 50%;
  }
}

.input {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: var(--matterColorLightGray);
  border: none;
  margin: 12px 12px 12px 0;

  @media (--viewportLarge){
    height: 100%;
  }
}

.noSpam {
  margin: 0;
  margin-right: auto;
  font-size: 14px;
  width: 100%;

  @media (--viewportLarge){
    width: 65%;
    margin: auto;
  }

  @media (--viewportLargeWithPadding){
    width: 50%;
    margin: auto;
  }
}

.newstellerTrue{
  margin: 0;
  margin-right: auto;
  font-size: 14px;
  width: 100%;
  color: var(--successColor);

  @media (--viewportLarge){
    width: 65%;
    margin: auto;
  }

  @media (--viewportLargeWithPadding){
    width: 50%;
    margin: auto;
  }
}