@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  flex-grow: 1;
  width: calc(100% - 48px);
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 36px;
  background-color: var(--matterColorBright);
  margin: 24px auto;

  @media (--viewportLarge){
    margin: 24px auto;
    width: 50%;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-top: 0;
  margin-bottom: 19px;
  line-height: 1.1;

  @media (--viewportMedium) {
    font-size: 30px;
    padding: 1px 0 7px 0;
  }
}

.priceCurrencyInvalid {
  color: var(--failColor);
}
