@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportMedium) {
    padding: 8px 16px; 
  }

  @media (--viewportLarge) {
    padding: 8px 45px; 
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 56px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

.categories{
  margin: auto 0;
  margin-right: auto;
}

.link{
  color: var(--matterColorDark);
  text-align: center;
  width: 300px;
  margin: auto 20px;
}

/* Search */
.searchLink {
  height: 100%;
  margin-right: 12px;
  width: 200px;

  display: flex;
  align-items: center;
  padding-left: 24px;

  position: relative;
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  background-color: var(--matterColorLightGray);
  border-radius: 6px;
  border: none;
  font-size: 14px;
  padding-left: 34px;
}

/* Create listing (CTA for providers) */
.createListingLink {
  display: none;
  @apply --TopbarDesktop_linkHover_listing;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  padding: 13px 27px 13px 28px;
  border-radius: 6px;
  background-color: var(--marketplaceColor);
  color: white;
  font-weight: bold;
  @apply --TopbarDesktop_label_listing;

  &:hover {
   background-color: var(--marketplaceColorDark);
 }
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */

.menu{
  padding-right: 45px;
}

.rootMenu{
  width: max-content;
  height: 100%;
  position: relative;
  outline: none;
}

.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 50px;
  padding-left: 12px;
  max-width: 200px;


  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.dropdown{
  margin: auto;
}

.profileMenuContent {
  /* min-width: 276px; */
  width: 150px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0px 12px 0px 12px;
  border-radius: 4px;
  color:#3b82f6;

  &:hover,
  &:focus {
    color: var(--matterColorDark);
    border: none;
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: #3b82f6;



  &:hover {
    color: var(--matterColorDark);
  }
}

.signup,
.login {
  @apply --marketplaceH5FontStyles;
  @apply --TopbarDesktop_label;
  font-weight: bold;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;
  font-size: 12px;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 12px;

  /* Dimensions */
  position: relative;
  width: 100%;
  /* min-width: 276px; */
  min-width: 150px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: #ef4444;
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  /* border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative); */

  &:hover {
    /* color: var(--matterColorDark); */
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    /* margin-top: 10px; */
    margin-bottom: 0;
  }
}
.currentUserName{
  @media (--viewportMedium) {
    font-size: 14px;
    margin: 0 0 1px;
    font-family: 'Roboto';
    /* margin-top: 0;
    margin-bottom: 2px; */
  }
}
.currentUserInfo{
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-left: 10px;
  align-items: center;
}
.postanoffer{
  @media (--viewportMedium) {
    margin: 1px 1px 0 0;
    font-size: 12px;
    font-family: 'Roboto';
    color: #3b82f6;
  }
}
.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.externalLink,
.dropbtn {
  color:black;
  padding: 16px 8px;
  font-size: 16px;
  border: none;

  @media (--viewportLarge) {
    padding: 16px;
  }
}

.dropdown {
  display: inline-block;

  @media (--viewportLargeWithPadding) {
    display: block;
  }
}

.subcategoriesTitle{
  width: fit-content;
  margin-right: auto;
  padding: 0 12px;

  color: var(--matterColorDark);
  font-weight: bold;
}

.subcategories {
  justify-content: center;

  display: block;
  color: var(--matterColorDark);
  padding: 0;
  font-size: 12px;
  margin: 0;
}

.dropdownContent {
  display: none;
  position: absolute;
  left: 0px;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  opacity: 0;
  background-color: var(--matterColorBright);
  border-radius: 6px;

  padding: 24px;
  width: 100%;
  height: 65vh;

  overflow: auto;

  @media (--viewportLarge) {
    height: 75vh;
  }
}

.dropdownContent a:hover {
  background-color: var(--matterColorBright);
}

.dropdown:hover .dropdownContent {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(1, minmax(calc(100% - 12px), 1fr));
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(1, minmax(calc(100% - 12px), 1fr));
  }
}
.dropdown:hover>.dropdownContent {transition-delay: 2s; opacity: 1;}

.dropdown:hover .dropbtn {background-color: matterColorBright;}

.column{
  display: flex;
  flex-direction: column;
  position: relative;
}

.columns{
  column-count: 5;
  padding: 0 12px;
  height: 100%;
}

.column::after {
  content: '';
  height: 60%; 
  width: 1px;

  position: absolute;
  right: 0;
  top: 10%; 

  background-color: var(--matterColor); 
}

.column:last-of-type::after,
.column:nth-of-type(3n):after {
  display: none;
  @media (--viewportLarge){
    display: block; 
  }
}

.column:last-of-type::after,
.column:nth-of-type(5n):after {
  @media (--viewportLarge){
    display: none;
  }
}
