@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: calc(100% - 48px);
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 36px;
  background-color: var(--matterColorBright);
  margin: 24px auto;

  @media (--viewportLarge){
    margin: 24px auto;
    width: 50%;
  }
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* This is the title for the Edit Photos component */
.title {
  margin-top: 0;
  margin-bottom: 19px;

  @media (--viewportLarge) {
    font-size: 30px;
    padding: 1px 0 7px 0;
  }
}
