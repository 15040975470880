.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 15px;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.bookingType {
  width: 100%;
  & label {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

.fieldDateInput {
  flex-grow: 1;
  & label {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
  }

  & input {
    padding-top: 7px !important;
    padding-bottom: 8px !important;
  }
}

.timeFields {
  display: flex;
  width: 100%;
}

.timeFieldsNone {
  display: none;
  width: 100%;
}

.startTime {
  width: 50%;
}

.startTimeGuides {
  width: 100%;
  border-radius: 15px!important;
}

.endTime {
  width: 50%;
}

.endTimeNone {
  display: none;
  width: 50%;
}

.fieldSelect {
  flex-grow: 1;
  margin-left: 24px;
  margin-right: 24px;
  & label {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.fieldSelectNoIcon {
  flex-grow: 1;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 12px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  & label {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
  }
}

.select {
  position: relative;
  padding: 7px 16px 8px 24px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;
  background-color: #fff!important;
    height: 48px;

  @media (--viewportMedium) {
  }
}

.selectDisabled {
  composes: select;
  padding: 7px 16px 8px 24px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
  background-color: #fff!important;
    height: 48px;
}

.startTimeSelect {
  @media (--viewportMedium) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 1px;
  }
}

.endTimeSelect {
  @media (--viewportMedium) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 1px;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

/*.field {
  width: calc(50% - 5px);
  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}*/

.startDate {
  width: 100%;
}
.startDateHalf {
  width: 50%;
}
.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin-top: 36px;
}
.lineBetweenDisabled {
  color: var(--matterColorNegative);
}

/**
CSS for hiding the end date above
**/
