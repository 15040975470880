@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
}

.email {
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  & > label {
  	font-size: 11px;
  	font-weight: bold;
  	line-height: 1.2;
  	@media (--viewportMedium) {
			font-size: 14px;
			line-height: 1.2;
			padding-bottom: 0;
		}
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 35px;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}
