@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.threeColumns {
  @media (--viewportMedium) {
    columns: 3;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.description {
  margin: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-bottom: 6px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: bold;
}