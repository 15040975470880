@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    width: 100%;
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  & label {
    font-weight: bold;
  }
}

.description {
  composes: formMargins;
  flex-shrink: 0;

  & label {
    font-weight: bold;
  }
}

.certificate {
  composes: formMargins;
  border-color: #c5c5c5;
  & label {
    font-weight: bold;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }
}

.phoneHelper {
  @apply --marketplaceSmallFontStyles;
  font-weight: 500;
  color: var(--matterColorAnti);
  text-align: left;
  margin: 24px 0;
  flex-shrink: 0;
}