@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 24px 0 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (1 / 1));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: #f2f2f2;

  border-style: dashed;
  border-color: transparent;
  border-width: 2px;
  border-radius: 15px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  padding: 0;

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzoneBase {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 53px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #e6e6e6;
  border-style: dashed;
  color: #8f8f8f;
  outline: none;
  transition: border .24s ease-in-out;

  @media (--viewportLarge) {
    padding: 53px;
  }
}

.dropzoneActive {
  border-color: var(--marketplaceColor);
}

.dropzoneAccept {
  border-color: var(--successColor);
}

.dropzoneReject {
  border-color: #ff1744;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.statusBoxWrapper {
  margin-top: 25px;
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }
}

.descriptionText {
  margin: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-bottom: 6px;
}

.labelText {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  & label {
    font-weight: bold;
  }
}

.description {
  composes: formMargins;
  flex-shrink: 0;

  & label {
    font-weight: bold;
  }
}

.certificate {
  composes: formMargins;
  border-color: #c5c5c5;
  & label {
    font-weight: bold;
  }
}

.error {
  color: var(--failColor);
}