@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 24px;
}

.title {
  @apply --marketplaceH1FontStyles;
  font-size: 26px;
  margin-top: 0;
  color: black;
  font-weight: 600;
}

.featured{
  display: grid;
  justify-content: center;
  width: 100%;
  gap: 12px;

  grid-template-columns: repeat(1, minmax(calc(100% - 50px), 1fr));

  @media (--viewportMedium) {
    gap: 24px;
    grid-template-columns: repeat(2, minmax(calc(40% - 24px), 1fr));
    padding: 0 48px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, minmax(calc(25% - 24px), 1fr));
  }
  
  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, minmax(calc(20% - 24px), 1fr));  
  }
}
.buttonWrapper {
	margin-top: 20px;
}

.seeMoreButton {
	max-width: 150px;
	margin: 0 auto;
  background-color: #60a5fa;
  border-radius: 6px;
}

.tab{
  width: 100%;
  font-family: var(--secondaryFontFamily);
  font-weight: bold;
  padding: 0 15px;
  margin-bottom: 12px;
  @media (--viewportMedium){
    width: 100%;
    font-size: 20px;
    line-height: 32px;
  }
}

.tabButton{
  background-color: var(--matterColorBright);
  color: var(--matterColor);
  border-style: solid;
  border-radius: 6px;
  border-color: transparent;
}

.tabButtonSelected{
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  border-style: solid;
  border-radius: 6px;
  border-color: transparent;
}

.tabsContainer{
  justify-content: center;

  @media (--viewportMedium){
    justify-content: center;
  }
}

.tabs{
  margin: 0;
  @media (--viewportLargeWithPadding){
    margin: 32px;
  }
}