@import '../../styles/propertySets.css';

.root {
  padding: 24px;
  text-align: center;

  @media (--viewportLargeWithPaddings) {
    text-align: left;
    padding: 24px 50px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: grid;
  justify-content: center;
  gap: 12px;

  grid-template-columns: repeat(1, minmax(calc(100% - 12px), 1fr));

  @media (--viewportMedium) {
    gap: 50px;
    grid-template-columns: repeat(3, minmax(calc(33.33% - 50px), 1fr));
  }
}

.stepTitle {
  margin-top: 15px;
}

.stepDescription{
  font-size: 14px;
}

.createListingLink {
  margin-top: 18px;
  color: white;
  font-size: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
