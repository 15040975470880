@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  background-color: var(--marketplaceColorSky);
  padding: 0 24px;
  text-align: center;

  @media (--viewportLargeWithPaddings){
    text-align: left;
    padding: 36px 64px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);
  padding-top: 24px;
  margin: 0;

  @media (--viewportLargeWithPaddings){
    margin: 0 25px;
  }
}

.subtitle {
  font-size: 14px;
  margin: 0 0 24px;
  color: var(--matterColorDark);
  @media (--viewportLarge){
    margin: 0 25px 24px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
  }
}

.image {
  display: none;
  @media (--viewportLargeWithPaddings){
    display: block;
    width: 80%;
  }
}

.mobile {
  display: block;
  @media (--viewportLargeWithPaddings){
    display: none;
  }
}

.buttonSection {
  display: flex;
  width: 100%;
  margin: auto;

  @media (--viewportLargeWithPaddings){
    width: 20%;
  }
}

.button {
  width: 75%;
  margin: 0 auto 24px;
  text-align: center;
  padding: 15px 15px;

  background-color: var(--marketplaceColor);
  color: var(--matterColorBright);

  border: none;
  border-radius: 6px;

  @media (--viewportLargeWithPaddings){
    margin: 0 auto;
    width: 80%;
  }
}