@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorLightGray);

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  text-align: center;

  @media (--viewportLarge) {
    padding: 56px 36px 0 36px;
    flex-direction: row;
    overflow-x: auto;
    box-shadow: none;
    border-top: none;
    justify-content: center;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 56px calc((100% - 1056px) / 2) 0 calc((100% - 1056px) / 2);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
