.root {
    margin: 29px 24px 0 24px;
  
    @media (--viewportMedium) {
      margin: 24px 24px 0 24px;
      padding: 1px 0 7px 0;
    }
  
    @media (--viewportLarge) {
      max-width: 100%;
      margin: 0 0 76px 0;
      padding: 0;
    }  
}

.videoForm{
    display: flex;
    flex-direction: column;
    
    width: 100%;
}

.videoButton {
    background-color: var(--marketplaceColor);
    border-radius: 6px;
    color: var(--matterColorBright);

    font-size: 18px;
    font-weight: 700;
    text-align: center;

    padding: 12px;
    width: 100%;
}